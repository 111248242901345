<template>
    <div class="wrap-plan">
        <CRow>
            <CCard class="cardERC-form">
                <CCardBody v-on:change="isFormChange=true">
                    <ValidationObserver ref="observer"  @submit.prevent="submitAction">
                        <CForm>
                            <div class="cont-stak">
                                <div class="info-stak">
                                    <label class="tit-plan">{{$t("ADD_PLAN_PAGE_TITLE_STAKING_INFORMATION")}}</label>
                                    <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_PLATFORM')">
                                        <div class="select-wrap form-group">
                                            <label>{{$t("ADD_PLAN_PAGE_LABEL_PLATFORM")}}</label>
                                            <select class="form-control role-select" v-model="platform.platform" disabled>
                                                <option
                                                        v-for="option in options"
                                                        v-bind:value="option.symbol"
                                                        :key="option.symbol"
                                                >{{ option.symbol }}</option>
                                            </select>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_TOKEN_NAME')">
                                        <div>
                                            <CInput
                                                    :label="$t('ADD_PLAN_PAGE_LABEL_TOKEN_NAME')"
                                                    :placeholder="$t('ADD_PLAN_PAGE_PLACEHOLDER_TOKEN_NAME')"
                                                    horizontal
                                                    v-model="platform.name"
                                                    class="form-group--custom"
                                                    disabled
                                            />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_TOKEN_CODE')">
                                        <div>
                                            <CInput
                                                    :label="$t('ADD_PLAN_PAGE_LABEL_TOKEN_CODE')"
                                                    :placeholder="$t('ADD_PLAN_PAGE_PLACEHOLDER_TOKEN_CODE')"
                                                    horizontal
                                                    v-model="platform.symbol"
                                                    class="form-group--custom"
                                                    disabled
                                            />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_TOKEN_ADDRESS')">
                                        <div>
                                            <CInput
                                                    :label="$t('ADD_PLAN_PAGE_LABEL_TOKEN_ADDRESS')"
                                                    :placeholder="$t('ADD_PLAN_PAGE_PLACEHOLDER_TOKEN_ADDRESS')"
                                                    horizontal
                                                    v-model="platform.sc_token_address"
                                                    class="form-group--custom"
                                                    disabled
                                            />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_LOOKING_CONTRACT')">
                                        <div class="form-group form-look">
                                            <label>{{$t("ADD_PLAN_PAGE_LABEL_LOCKING_CONTRACT")}}</label>
                                            {{platform.sc_lookup_addr}}
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="info-plan">
                                <label class="tit-plan">{{$t("ADD_PLAN_PAGE_TITLE_PLAN_INFORMATION")}}</label>
                                <div class="cont-plan">
                                    <div class="left-plan">
                                        <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_NAME')" rules="required|max:20" v-slot="{ errors }">
                                            <div>
                                                <CInput
                                                        :label="$t('ADD_PLAN_PAGE_LABEL_NAME')"
                                                        :placeholder="$t('ADD_PLAN_PAGE_PLACEHOLDER_NAME')"
                                                        v-model="plan.name"
                                                        horizontal
                                                        class="form-group--custom"
                                                />
                                                <CRow>
                                                    <CCol :sm="{offset:'3'}"></CCol>
                                                    <CCol :sm="9" class="wrap-error">
                                                        <p class="error-msg">{{errors[0]}}</p>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_DURATION')" rules="required" v-slot="{ errors }">
                                            <div>
                                                <CInput
                                                        :label="$t('ADD_PLAN_PAGE_LABEL_DURATION')"
                                                        :placeholder="$t('ADD_PLAN_PAGE_PLACEHOLDER_DURATION')"
                                                        v-model="plan.duration"
                                                        horizontal
                                                        class="form-group--custom"
                                                />
                                                <CRow>
                                                    <CCol :sm="{offset:'3'}"></CCol>
                                                    <CCol :sm="9" class="wrap-error">
                                                        <p class="error-msg">{{errors[0]}}</p>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </ValidationProvider>      
                                        <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_REWARD_PERCENTAGE')" rules="required|min_value:0|max_value:100" v-slot="{ errors }">
                                            <div>
                                                <CInput
                                                        :label="$t('ADD_PLAN_PAGE_LABEL_REWARD_PERCENTAGE')"
                                                        :placeholder="$t('ADD_PLAN_PAGE_PLACEHOLDER_REWARD_PERCENTAGE')"
                                                        v-model="plan.reward_percentage"
                                                        type="number"
                                                        horizontal
                                                        class="form-group--custom"
                                                        
                                                />
                                                <CRow>
                                                    <CCol :sm="{offset:'3'}"></CCol>
                                                    <CCol :sm="9" class="wrap-error">
                                                        <p class="error-msg">{{errors[0]}}</p>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="right-plan">
                                        <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_UNITS')" rules="required" v-slot="{ errors }">
                                            <div class="select-wrap form-group unit">
                                                <label>{{$t("ADD_PLAN_PAGE_LABEL_UNITS")}}</label>
                                                <select class="form-control role-select" v-model="plan.duration_type">
                                                    <option
                                                            v-for="item in timeUnits"
                                                            v-bind:value="item"
                                                            :key="item">{{item}}</option>
                                                </select>
                                            </div>
                                            <CRow>
                                                <CCol :sm="{offset:'3'}"></CCol>
                                                <CCol :sm="9" class="wrap-error">
                                                    <p class="error-msg">{{errors[0]}}</p>
                                                </CCol>
                                            </CRow>
                                        </ValidationProvider>
                                        <ValidationProvider :name="$t('ADD_PLAN_PAGE_FIELD_NAME_VALIDATE_STATUS')" rules="required" v-slot="{ errors }">
                                            <div class="select-wrap form-group">
                                                <label class="tit-plan">{{$t("ADD_PLAN_PAGE_LABEL_STATUS")}}</label>
                                                <select class="form-control role-select" v-model="plan.status">
                                                    <option
                                                            v-for="item in status"
                                                            v-bind:value="item"
                                                            :key="item">{{$t(`ADD_PLAN_PAGE_OPTION_SELECT_${renderStatus(item)}`)}}</option>
                                                </select>
                                            </div>
                                            <CRow>
                                                <CCol :sm="{offset:'3'}"></CCol>
                                                <CCol :sm="9" class="wrap-error">
                                                    <p class="error-msg">{{errors[0]}}</p>
                                                </CCol>
                                            </CRow>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="btn-pay">
                                    <!-- <CButton class="erc-cancel" @click="openWarningLeaveModal">Cancel</CButton> -->
                                    <CButton type="submit">{{$t("ADD_PLAN_PAGE_BUTTON_SAVE")}}</CButton>
                                </div>
                            </div>
                            
                        </CForm>
                    </ValidationObserver>
                </CCardBody>
            </CCard>
        </CRow>
        
        <CModal id="leavePageModal" :show.sync="warningLeavePage" :centered="true">
            <div class="">
                <div class="tit-leave">
                <h4>{{$t("ADD_PLAN_PAGE_TITLE_UNSAVED_CHANGES")}}</h4>
                </div>
                <p class="text-modal">{{$t("ADD_PLAN_PAGE_CONTENT_UNSAVED_CHANGES")}}</p>
                <p class="text-modal">{{$t("ADD_PLAN_PAGE_QUESTION_UNSAVED_CHANGES")}}</p>
            </div>

            <template #footer>
                <CButton
                class="stay-btn"
                @click="warningLeavePage = false"
                color="dark"
                variant="outline"
                >{{$t("ADD_PLAN_PAGE_BUTTON_STAY_ON_THIS_PAGE")}}</CButton>
                <router-link class="leave-btn" to="/admin/staking-platform">{{$t("ADD_PLAN_PAGE_BUTTON_LEAVE_THIS_PAGE")}}</router-link>
            </template>
        </CModal>
    </div>
</template>
<script>
    import Draggable from "vuedraggable";
    import endpoints from "@/constants/endpoints";
    import { mapState, mapActions } from "vuex";
    export default {
        components: {
            Draggable
        },
        data() {
            return {
                platforms: [],
                plan: {},
                isFormChange: false,
                warningLeavePage: false,
                options: [],
                status:[
                    1,
                    0,
                ],
            };
        },
        computed: {
            ...mapState({
                platform: state => state.platform.selectedPlatform,
                timeUnits: state => state.platform.timeUnits,
            }),
        },
        async mounted() {
            await this.getPlatforms();
            await this.fetchTimeUnit();
        },
        methods: {
            ...mapActions([
                "AddStakingPlan",
                "fetchTimeUnit",
            ]),
            async getPlatforms() {
                let result = await this.$http.get(
                    endpoints.getStakingPlatformConfig,
                );
                if (result.data) {
                    this.options = result.data
                }
            },
            async submitAction() {
                const isValid = await this.$refs.observer.validate();
                if(!isValid) return;
                const payload = {
                    ...this.plan,
                    staking_platform_id: this.platform.id,
                }
                const response = await this.AddStakingPlan(payload);
                if (response) {
                    this.$router.back()
                }
            },
            renderStatus(item) {
                // if (item === -2) {
                //     return 'Hidden'
                // }
                if (item === 0) {
                    return 'DEACTIVE'
                }
                // if (item === 0) {
                //     return 'Coming Soon'
                // }
                if (item === 1) {
                    return 'ACTIVE'
                }
            },
            openWarningLeaveModal(){
                if(this.isFormChange){
                    this.warningLeavePage=true;
                }else{
                    this.$router.push('/admin/staking-platform');
                }
                console.log(this.isFormChange);
            },
        },
    };
</script>
<style lang="scss">
    .wrap-plan{     
        padding: 15px 40px;
        .cardERC-form{
            background-color: unset;
            border: none;
        }
        .card{
            width: 75%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            .card-body{
                padding: 0
            }
            .tit-plan{
                color: #657187;
                font-size: 18px;
                font-weight: 600;
                line-height: 34px;
            }
            .form-look{
                label{
                    width: 25%
                }
            }
            // .num-pay{
            //     input{
            //         width: 25% !important;
            //     }
            // }
            .cont-stak {
                width: 100%;
                background: #fff;
                .info-stak{
                padding: 25px 10px 10px 25px;
                
                width:63%;
                // .form-group{
                //     width: 65%;
                // }
                .form-group{
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 1200px){
                    padding: 25px;
                    width: 100%;
                    }
            }
            }
            
            .info-plan{
                margin-top: 25px;
                padding: 25px;
                background: #fff;
                .left-plan{
                    width: 65%;
                    float: left;
                    @media screen and (max-width: 1250px){
                        width: 60%;
                    }
                     @media screen and (max-width: 900px){
                        width: 100%;
                    }
                }
                .right-plan{
                    width: 35%;
                    float: right;
                    padding-left: 30px;
                    @media screen and (max-width: 1250px){
                        width: 40%;
                    }
                    @media screen and (max-width: 900px){
                        width: 100%;
                        padding-left: 0;
                    }
                }
            }
            .select-wrap{
                padding-right: 10px;
                margin-bottom: 15px;
                label{
                    width: 33%
                }
                select{
                    margin-left: 5px;
                }
                &.unit{
                    margin-bottom: 15px;
                     select{
                        margin-left: 10px;
                    }
                }
            }
            .form-group{
                display: flex;
                align-items: center;
                width: 100%;
                
                label{
                    text-align: right;
                    /* padding-right: 15px; */
                    padding: 0 10px 0 0;
                    line-height: 20px;
                    color: #3e4b65;
                    font-size: 14px;
                    align-self: center;
                    font-weight: 500;
                    letter-spacing: .5px;
                    margin-bottom: 0;
                    @media screen and (max-width: 600px){
                        text-align: left;
                        width: 100%;
                    }
                }
                
                // input,select{
                //     width: 90%;
                // }
                // select{
                //     width: 90%;
                // }
            
            }
            .btn-pay{
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                // padding-right: 50px;

                button[type="submit"]{
                    min-width: 146px;
                    height: 36px;
                    border-radius: 4px;
                    background-color: #3b4b7e;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: .5px;
                    border: none;
                    outline: none!important;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    text-align: center
                }
            }
            
        }
        .wrap-error{
            padding-left: 10px;
            .error-msg{
                color: red;
                margin-top: -10px;
                margin-bottom: 26px;
            }   
        }
        .pr-10{
            padding-right: 10px;
            margin-bottom: 15px;
        }
    }
        
#leavePageModal{
  .modal-dialog{
    max-width: 380px;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 24px 20px 0 20px;
      .tit-leave{
        margin-bottom: 20px;
        h4{
          color: #4A4A4A;
          /* font-family: "SF UI Display"; */
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin: 0;
        }
      }
      .text-modal{
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    .modal-footer{
      border: none;
      justify-content: space-between;
      padding: 25px 20px 12px 20px;
      .stay-btn{
        border: 1px solid #CBCBCB;
        border-radius: 5px;
        height: 37px;
        line-height: 37px;
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        line-height: 16px;
        padding: 0 20px;
        &:hover,
        &:visited{
          outline: none;
          background: #fcfcfc;
          color: #4A4A4A;
        }
      }
      .leave-btn{
        height: 37px;
        line-height: 37px;
        padding: 0px 20px;
        border-radius: 4px;
        background-color: #3B4B7E;
        color: #FFFFFF;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
</style>